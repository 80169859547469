// Used https://hdoro.dev/gatsby-live-preview-sanity/
// @ts-ignore
import * as queryString from "query-string";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import sanityClient from "@sanity/client";
import Article, { ArticleInterface } from "../Article";
import Product, { ProductInterface } from "../Product";
import CampaignPage, { CampaignPageInterface } from "../CampaignPage";
import SectionPage, { SectionPageInterface } from "../SectionPage";
import SubSectionPage, { SubSectionPageInterface } from "../SubSectionPage";
import ProductLinePage, { ProductLinePageInterface } from "../ProductLinePage";
import { ProductLandingPageQueryProps } from "../../templates/ProductLandingPage";
import BlockRenderer from "../BlockRenderer";
import PageHero from "../PageHero";
import { articleQuery } from "../../utils/queries/articleQuery";
import { productQuery } from "../../utils/queries/productQuery";
import { landingPageQuery } from "../../utils/queries/landingPageQuery";
import imageUrlBuilder from "@sanity/image-url";
import { normalizeSanityData } from "../../utils/normalizeSanityData";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { ImageInterface, PortableText, SanityLabelsInterface } from "../../types/SanityTypes";
import { LocalizedContext } from "../../services/LocalizedContextService";

const PreviewTemplate: FunctionComponent<PreviewTemplateInterface> = ({ location }) => {
  const { sanityId, sanityDataset, sanityPreviewToken } = useSiteMetadata();
  const { sanityLabels, sanityProductLandingPage } = useContext(LocalizedContext);
  const { productLabels, articleSliderHeading } = sanityLabels as SanityLabelsInterface;
  const [data, setData] = useState<Record<string, unknown>>({});

  useEffect(() => {
    let timeToFetchAfterMutation: NodeJS.Timeout;

    const renderQuery = () => {
      if (location.href.includes(`pageType=productLandingPage`)) return landingPageQuery;
      if (location.href.includes(`pageType=productLinePage`)) return landingPageQuery;
      if (location.href.includes(`pageType=product`)) return productQuery;
      if (location.href.includes(`pageType=article`)) return articleQuery;
      if (location.href.includes(`pageType=landingPage`)) return landingPageQuery;
      if (location.href.includes(`pageType=sectionPage`)) return landingPageQuery;
      if (location.href.includes(`pageType=homePage`)) return landingPageQuery;
      if (location.href.includes(`pageType=subSectionPage`)) return landingPageQuery;
    };

    const fetchDataFromSanity = async (_id: string, isDraft: false) => {
      const client = sanityClient({
        projectId: sanityId,
        dataset: sanityDataset,
        token: sanityPreviewToken,
        useCdn: true,
        withCredentials: true
      });

      const query = `*[_id == '${isDraft ? `drafts.${_id}` : _id}']{${renderQuery()}}`;
      const queryNoWhiteSpaces = query.replace(/\s+/g, "");
      const crudeData = await client.fetch(queryNoWhiteSpaces);
      if (crudeData && crudeData[0]) {
        const builder = imageUrlBuilder(client);
        const getImageURL = (image: SanityImageSource) => builder.image(image).url();
        const normalizedData = await normalizeSanityData({
          data: crudeData[0],
          getImageURL
        });
        if (normalizedData) {
          return normalizedData;
        }
      } else {
        return {
          data: undefined,
          getImageURL: undefined
        };
      }
    };

    const fetchData = async () => {
      const query = queryString.parse(location.search);
      const { pageId, isDraft } = query;

      return fetchDataFromSanity(pageId, isDraft);
    };

    const subscribeToData = (pageId: string, callback: () => Promise<Record<string, unknown> | undefined>) => {
      const client = sanityClient({
        projectId: sanityId,
        dataset: sanityDataset,
        token: sanityPreviewToken,
        useCdn: true,
        withCredentials: true
      });
      const query = `*[_id == 'drafts.${pageId}']{${renderQuery()}}`;
      const queryNoWhiteSpaces = query.replace(/\s+/g, "");
      return client.listen(queryNoWhiteSpaces, {}, { includeResult: false }).subscribe(() => {
        clearTimeout(timeToFetchAfterMutation);
        timeToFetchAfterMutation = setTimeout(() => {
          callback();
        }, 500);
      });
    };

    const { pageId } = queryString.parse(location.search);
    fetchData().then(sanityData => {
      if (sanityData) setData(sanityData);
    });
    const subscription = subscribeToData(pageId, fetchData);

    return () => {
      subscription.unsubscribe();
    };
  }, [location.href, location.search, sanityProductLandingPage?.slug, sanityDataset, sanityId, sanityPreviewToken]);

  return (
    <div>
      {data && data["_type"] === "article" && (
        <Article
          data={(data as unknown) as ArticleInterface["data"]}
          articleRecommendationLabel={articleSliderHeading as string}
        />
      )}
      {data && data["_type"] === "product" && (
        <Product
          sanityProduct={(data as unknown) as ProductInterface}
          sanityProductLandingPage={sanityProductLandingPage}
          productLabels={productLabels}
        />
      )}
      {data && data["_type"] === "landingPage" && (
        <CampaignPage data={(data as unknown) as CampaignPageInterface["data"]} />
      )}
      {data && data["_type"] === "sectionPage" && (
        <SectionPage data={(data as unknown) as SectionPageInterface["data"]} />
      )}
      {data && data["_type"] === "subSectionPage" && (
        <SubSectionPage data={(data as unknown) as SubSectionPageInterface["data"]} />
      )}
      {data && data["_type"] === "productLinePage" && (
        <ProductLinePage data={(data as unknown) as ProductLinePageInterface["data"]} />
      )}
      {data &&
        data["_type"] === "homePage" &&
        ((data as unknown) as SectionPageInterface["data"]).landingSections.map((section, index) => (
          <BlockRenderer key={"landingSection" + index} section={section} />
        ))}
      {data && data["_type"] === "productLandingPage" && (
        <>
          <PageHero
            lineOne={(data.headline as Record<string, unknown>).primaryText as string}
            image={data._rawHeroImage as ImageInterface}
            text={data._rawIntroduction as PortableText}
            textPosition="align-self-start"
            themeColor={"var(--orange-bright-color)"}
            titlePosition="middle"
            gradientBackground={true}
          />
          {((data as unknown) as ProductLandingPageQueryProps["productLandingPage"]).landingSections.map(
            (section, index) => (
              <BlockRenderer key={"landingSection" + index} section={section} />
            )
          )}
        </>
      )}
    </div>
  );
};

export interface PreviewTemplateInterface {
  location: Location;
}

export default PreviewTemplate;
