import * as React from "react";
import { Helmet } from "react-helmet";
import PreviewTemplate from "../components/PreviewTemplate";
import Layout from "../components/Layout";
import { PageProps } from "gatsby";
import { emptyLocalizedContext } from "../services/LocalizedContextService";

const Preview = (props: PageProps) => {
  return (
    <div>
      <Helmet>
        <title>Preview</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Layout pageName={"Preview"} pageType={"preview"} localizedContext={emptyLocalizedContext}>
        <PreviewTemplate location={props.location} />
      </Layout>
    </div>
  );
};

export default Preview;
