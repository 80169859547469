export const landingPageQuery = `
  _id,
  _type,
  slug{
    current
  },
  parentSection->{
    slug{
      current
    }, 
    main{
      sectionColor{
        value, 
        title
      }
    }
  },
  headline{
    primaryText,
    secondaryText,
    tertiaryText
  },
  '_rawTextBody': textBody,
  heading,
  publishedAt,
  '_rawIntroduction': introduction,
  '_rawHeroImage': heroImage {...},
  seo{
    metaDescription,
    metaTitle
  },
  landingSections[]->{
    ..., 
    articleDoubleTeaserBlockType->{...},
    articleSingleTeaserBlockType->{...},
    sectionBlockType->{...},
    promoBlockType->{...},
    content[]->{..., parentSection->{slug{current}, parentSection->{slug{current}}}},
    ctaReference->{
      ...,
      main->{...},
      parentSection->{..., parentSection->{..., slug{current}}},
      surveyBlock->{..., questions[]{...}},
      teaser->{
        ...,
        sectionTeaserHeadline->{primaryText, secondaryText, tertiaryText},
        teaserHeadline->{primaryText, secondaryText, tertiaryText}
      }
    },
    slug{
      current
    },
    ctaLink->{
      ..., 
      surveyBlock->{..., questions[]{...}}
    },
    displayReviews,
    parentSection->{...},
    '_rawImage': image {..., asset->{...}},
    video{..., asset->{...}},
    surveyModal->{
      ...,
      surveyBlock->{..., questions[]{...}}
    },
    videoAsset{
      ...,
      video{..., asset->{...}},
      videoType->{...}
    }
  },
  main{
    sectionNumber,
    sectionColor{
      value,
      title
    },
    gradientBackground,
    headline,
    '_rawShortIntro': shortIntro,
    '_rawHeroImage': heroImage {...}
  },
  pageModal->{
    ...,
    surveyBlock->{..., questions[]{...}}
  }
`;
