// Used https://hdoro.dev/gatsby-live-preview-sanity/

import { SanityImageSource } from "@sanity/image-url/lib/types/types";

export type TGetImageURL = (image: SanityImageSource) => string | null;

export interface ISanityDataGetImage {
  getImageURL: TGetImageURL;
  data: Record<string, unknown>;
}

const isImagelessObject = (field: unknown) => {
  //@ts-ignore
  return typeof field == "object" && field._type !== "image";
};

const isImage = (field: unknown) => {
  //@ts-ignore
  return typeof field == "object" && field._type && field._type === "image" && field.asset;
};

const saveImage = async (field: Record<string, unknown>, getImageURL: TGetImageURL) => {
  // Build the URL for the image using Sanity's package
  const imageUrl = await getImageURL(field);
  let newField = { ...field };
  if (imageUrl) {
    newField = {
      ...field,
      imageUrl
    };
  } else {
    console.error("Failed to save an image");
  }

  return newField;
};

const analyzeField = async (field: Record<string, unknown>, getImageURL: TGetImageURL) => {
  let finalField = field;
  for (const key of Object.keys(field)) {
    let newField = field[key];
    if (isImagelessObject(field[key])) {
      // if it's an object without an image, we want to go deeper
      // into its structure to check for images there
      newField = await analyzeField(newField as Record<string, unknown>, getImageURL);
    } else if (isImage(field[key])) {
      // If it's an image field with an asset, save the image
      newField = await saveImage(newField as Record<string, unknown>, getImageURL);
    } else {
      // If not an object, we simply skip this key
      continue;
    }

    // swap out the previous field with the new one
    finalField = Object.assign(finalField, {
      [key]: newField
    });
  }
  return finalField;
};

export const normalizeSanityData = ({ data, getImageURL }: ISanityDataGetImage) => {
  return analyzeField(data, getImageURL);
};
