import "./styles.scss";
import React, { FunctionComponent, useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { PortableText, ImageInterface, SanityProductLabelsInterface, Slug } from "../../types/SanityTypes";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import SocialShare from "../SocialShare";
import ContextAwareAccordionToggle from "../ContextAwareAccordionToggle";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Ingredients from "../Ingredients";
import SliderBlock from "../SliderBlock";
import ProductRatings from "../ProductRatings";
import ProductReviews from "../ProductReviews";
import WhereToBuyWidget, { WhereToBuyButton } from "web-common/src/components/WhereToBuy";
import { withPrefix } from "gatsby";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { LocalizedContext } from "../../services/LocalizedContextService";
import ProductRatingsAndReviewsScript from "../ProductRatingsAndReviewsScript";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import useScript from "../../hooks/useScript";
import { Helmet } from "react-helmet";
import { event38 } from "../../analytics/event38";
import { event17 } from "../../analytics/event17";
import { event18 } from "../../analytics/event18";
import { event19 } from "../../analytics/event19";
import { event20 } from "../../analytics/event20";

declare global {
  interface Window {
    kritiqueInitialized: boolean;
  }
}

export type ProductComponentInterface = {
  sanityProduct: ProductInterface;
  sanityProductLandingPage?: Slug;
} & SanityProductLabelsInterface;

export type ProductInterface = {
  _id: string;
  _rawDescription?: PortableText;
  _rawHowToUse?: PortableText;
  _rawImages: ImageInterface[];
  _rawIngredients?: PortableText;
  _rawPerfectFor?: PortableText;
  _rawTryThis?: PortableText;
  _rawAvailable?: PortableText;
  category?: {
    name: string;
  };
  displayReviews: boolean;
  name: string;
  seoShortDescription: string;
  shortDescription: string;
  shortTitle: string;
  size: string;
  slug: {
    current: string;
  };
  smartLabelId?: string;
  smartProductId?: string;
  jsonld?: string;
  upc?: string;
  tags?: [
    {
      tagName: string;
      tagCategory: {
        name: string;
      };
    }
  ];
};
export const Product: FunctionComponent<ProductComponentInterface> = props => {
  const { language, sanitySiteSettings } = useContext(LocalizedContext);
  const { locale, sanityId, sanityDataset, siteUrl, binConstCo, binCartWire, useShopalyst } = useSiteMetadata(language);
  const productLabels = props.productLabels;
  const product = props.sanityProduct;
  const productLandingPage = props.sanityProductLandingPage as Slug;
  const productImages = product._rawImages;
  let productParentPath = "";
  if (productLandingPage) {
    productParentPath = getLocalizedPath(productLandingPage.slug.current, product._id);
  }
  const countries: string[] = [];
  if (product.tags && product.tags.length > 0) {
    product.tags.filter(tag => {
      if (tag.tagCategory.name === "country") {
        countries.push(tag.tagName);
      }
    });
  }

  const pdpConfig = {
    pdpVersion: "1",
    lng: language
  };

  const displayOptions = {
    type: "lightbox",
    position: "center",
    width: "100%",
    maxWidth: "600px",
    height: "100%",
    closeButton: {
      type: "back",
      position: "left",
      color: "white"
    },
    hideLoader: true,
    closeOverlayOnClick: true
  };

  const loadShopaLyst = (callback?: Function) => {
    const existingScript = document.getElementById("shopalyst-script");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = useShopalyst.link;
      script.id = "shopalyst-script";
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };

  const createShopaConfigsScript = () => {
    if(_shopalyst){
      const configs = document.createElement("script");
      configs.innerText = _shopalyst.init("rexona", useShopalyst.placementId, useShopalyst.campaignId, false);
      configs.id = "shopalyst-configs-script";
      document.body.appendChild(configs);
    }
  };
  useEffect(() => {
    loadShopaLyst(createShopaConfigsScript);
  }, [useShopalyst?.link]);

  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const multipleImages = () => {
    if (product._rawImages.length > 1) {
      return <SliderBlock data={productImages} type={"multiple-products"} />;
    }
  };

  const countryFlag = () => {
    return countries.map(country => {
      return (
        <div className={"flag " + country} key={country}>
          <span className={"sr-only"}>{country}</span>
        </div>
      );
    });
  };

  // @ts-ignore
  return (
    <div className="product">
      <ProductRatingsAndReviewsScript />
      {binCartWire?.length > 0 ? (
        <Helmet
          onChangeClientState={() => {
            const cartWireScript = document.getElementById("cartwireScript") as HTMLScriptElement;
            event17(product.name);

            const openButtonInterval = setInterval(function () {
              const cartWireButton = document.getElementsByClassName("write-review-btn")[0] as HTMLButtonElement;
              if (cartWireButton) {
                clearInterval(openButtonInterval); // this stops the loop
                cartWireButton.onclick = () => {
                  event18(product.name);
                };
              }
            }, 1000);

            const closeReviewInterval = setInterval(function () {
              const closeWireButton = document.getElementById("Layer_1") as HTMLButtonElement;
              if (closeWireButton) {
                clearInterval(closeReviewInterval); // this stops the loop
                closeWireButton.onclick = () => {
                  event19(product.name);
                };
              }
            }, 1000);

            const submitReviewInterval = setInterval(() => {
              const formReview = document.getElementById("kr-button") as HTMLButtonElement;
              if (formReview) {
                clearInterval(submitReviewInterval); // this stops the loop
                formReview.onclick = () => {
                  if (formReview.disabled) {
                    event20(product.name);
                  }
                };
              }
            }, 1000);
          }}
        >
          <script id={"cartwireScript"} src={binCartWire}></script>
        </Helmet>
      ) : (
        <WhereToBuyWidget />
      )}
      <Container fluid>
        <Row className="specs">
          <Col md="6" className="picture">
            {countries?.length > 0 ? <div className={"country-flags"}>{countryFlag()}</div> : null}
            {productImages?.length === 1 ? (
              <picture
                data-testid="product-image"
                className="placeholder"
                style={{
                  ...(productImages[0].asset.metadata
                    ? {
                        paddingTop: `calc(25% / ${productImages[0].asset.metadata.dimensions.aspectRatio})`,
                        paddingBottom: `calc(25% / ${productImages[0].asset.metadata.dimensions.aspectRatio})`,
                        backgroundColor: "white",
                        backgroundSize: "cover"
                      }
                    : null)
                }}
              >
                <source
                  media="screen and (min-width: 576px)"
                  srcSet={`${urlBuilder
                    .image(productImages[0])
                    .auto("format")
                    .quality(80)
                    .width(362)
                    .height(395)
                    .format("webp")
                    .url()}`}
                />
                <img
                  src={
                    urlBuilder
                      .image(productImages[0])
                      .auto("format")
                      .quality(80)
                      .width(300)
                      .height(338)
                      .format("webp")
                      .url() || undefined
                  }
                  loading={"lazy"}
                  alt={productImages[0].alt}
                />
              </picture>
            ) : productImages?.length > 1 ? (
              multipleImages()
            ) : null}
          </Col>
          <Col md="6" className="title-and-description">
            <h1>{product.name}</h1>
            {product.displayReviews && (
              <ProductRatings productName={product.name} upc={product._id} template="inline" />
            )}
            <p className="short-description">{product.shortDescription}</p>
            <div className="category-and-size">
              {product.category && product.category.name}
              <div>{product.size}</div>
            </div>
            {/* ConstCo BIN */}
            {binConstCo?.length > 0
              ? (useScript(`${binConstCo}`, "0"),
                (
                  <div
                    className="constantco-widget"
                    data-type="sp-addtobasket-button"
                    data-ean={product.smartProductId}
                    data-app-resource-id={product.smartProductId}
                    data-params="mobile_breakpoint=769"
                  />
                ))
              : null}
            {/* Cartwire BIN */}
            <WhereToBuyButton
              productCode={product.smartProductId}
              productName={product.name}
              productCategory={product.category?.name || ""}
              brand={sanitySiteSettings.brandName}
            />
            {/* Shopalyst BIN */}
            {useShopalyst.link.length > 0 ? (
              <>
                <button
                  className="btn-primary"
                  onClick={() => {
                    if (_shopalyst) {
                      //@ts-ignore
                      const { _id, category, smartProductId, name } = product;
                      _shopalyst.openSlPdp(_id, "ean", {}, pdpConfig);
                      event38(`${smartProductId}`, name, category?.name || "", sanitySiteSettings.brandName);
                    }
                  }}
                >
                  {productLabels?.buyItNow ? productLabels?.buyItNow : "Buy it now"}
                </button>{" "}
              </>
            ) : null}
            {productLandingPage && product.slug && (
              <div style={{ minWidth: "100px" }}>
                <SocialShare
                  url={`${siteUrl}${withPrefix(productParentPath)}${product.slug.current}/`}
                  locale={locale}
                  name={product.name}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row className="details">
          <Col>
            <hr className="section-divider" />
            <Accordion>
              {product._rawAvailable && (
                <Card data-testid="product-perfect-for">
                  <ContextAwareAccordionToggle eventKey="available">
                    <h2>{productLabels?.available}</h2>
                  </ContextAwareAccordionToggle>
                  <Accordion.Collapse eventKey="available">
                    <Card.Body>
                      <BlockContent blocks={product._rawAvailable} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )}
              {product._rawDescription && (
                <Card data-testid="product-description">
                  <ContextAwareAccordionToggle eventKey="description">
                    <h2>{productLabels?.details}</h2>
                  </ContextAwareAccordionToggle>
                  <Accordion.Collapse eventKey="description">
                    <Card.Body>
                      <BlockContent blocks={product._rawDescription || {}} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )}
              {(product.smartLabelId || product._rawIngredients) && (
                <Card data-testid="product-ingredients">
                  <ContextAwareAccordionToggle eventKey="ingredients">
                    <h2>{productLabels?.ingredients}</h2>
                  </ContextAwareAccordionToggle>
                  <Accordion.Collapse eventKey="ingredients">
                    <Card.Body>
                      <Ingredients
                        text={product._rawIngredients as unknown as PortableText}
                        smartLabelId={product.smartLabelId}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )}
              {product._rawHowToUse && (
                <Card data-testid="product-how-to-use">
                  <ContextAwareAccordionToggle eventKey="how-to-use">
                    <h2>{productLabels?.howTo}</h2>
                  </ContextAwareAccordionToggle>
                  <Accordion.Collapse eventKey="how-to-use">
                    <Card.Body>
                      <BlockContent blocks={product._rawHowToUse} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )}
              {product._rawPerfectFor && (
                <Card data-testid="product-perfect-for">
                  <ContextAwareAccordionToggle eventKey="perfect-for">
                    <h2>{productLabels?.perfectFor}</h2>
                  </ContextAwareAccordionToggle>
                  <Accordion.Collapse eventKey="perfect-for">
                    <Card.Body>
                      <BlockContent blocks={product._rawPerfectFor} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )}
              {product._rawTryThis && (
                <Card data-testid="product-try-this">
                  <ContextAwareAccordionToggle eventKey="try-this">
                    <h2>{productLabels?.tryThis}</h2>
                  </ContextAwareAccordionToggle>
                  <Accordion.Collapse eventKey="try-this">
                    <Card.Body>
                      <BlockContent blocks={product._rawTryThis} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )}

              {(product.smartProductId || product._id) && product.displayReviews && (
                <ProductReviews
                  gtin={product._id}
                  upc={product.smartProductId || product._id}
                  productName={product.name}
                  label={productLabels?.reviews}
                  thumbnail={`${urlBuilder
                    .image(product?._rawImages ? product?._rawImages[0] : "")
                    .auto("format")
                    .quality(80)
                    .width(250)
                    .height(250)
                    .format("webp")
                    .url()}`}
                />
              )}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Product;
