export const articleQuery = `
  _id,
  _type,
  headline,
  slug{
    current
  },
  parentSection->{slug{current}, name, main{sectionColor{value, title}}, parentSection->{...}},
  tripleHeadline,
  publishedAt,
  rankLevel,
  articleDetails,
  socialShare,
  colorlist{
    value,
    title
  },
  '_rawSubheading': subheading,
  '_rawHeroImage': heroImage {...},
  '_rawTextBody': textBody[]{
    ...,
    video{..., asset->{...}},
    workoutVideo->{
      ...,
      workoutInfo{
        ..., 
        equipment[]->{...}, 
        focus[]->{...}
        level[]->{...},
        beginner[]->{...},
        intermediate[]->{...},
        advanced[]->{...},
        
      },
      videoAsset{
        ..., 
        videoType->{...},
        video{..., asset->{...}},
      }
    }
  },
  seo{
    metaDescription,
    metaTitle
  },
  articleRecommendation[]->{..., parentSection->{slug{current}}}
`;
